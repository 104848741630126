/* You can add global styles to this file, and also import other style files */
html, body {
    height: 100%;
}

body {
    background: #F1F1F1 !important;
    margin: 0;
    /*font-family: Roboto, "Helvetica Neue", sans-serif;*/
}

:root {
    --masmovil-color: #FFE200;
    --pepephone-color: #CC0000;
    --guuk-color: #72A325;
}

/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/
.mat-step-header .mat-step-icon {
    background-color: #E7E9ED;
    padding: 20px;
}

.mat-step-header .mat-step-icon-selected {
    background: #000000;

    padding: 20px;
}

.mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background: #55b032;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 43px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before {
    top: 43px !important;
}

.mat-horizontal-stepper-header-container {
    margin: auto;
    width: 58%;
}

.md-container .mat-dialog-container {
    background: transparent;
    box-shadow: none;
    padding: 0 !important;
}

.md-container-white .mat-dialog-container {
    background: white;
    border-radius: 0;
    box-shadow: none;
    padding: 0 !important;
}

.md-backdrop.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: black;
    opacity: 0.75;
}

.error-download-file {
    color: red;
    font-size: 14px;
    margin-left: 1rem;
}

.manual-address {
    align-items: center;
    background: white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .6);
    display: none;
    font-size: 14px;
    justify-content: space-between;
    padding: 8px 15px;
    position: absolute;
    top: 100%;
    width: calc(100% - 10px);
    z-index: 9999;
}

.manual-address-link {
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
}

.manual-address-text {
    cursor: context-menu;
}

.btn {
    &.btn-action-date-picker {
        font-weight: 600;
        color: #1f1f1f !important;
        text-decoration: none;
    }
}

.pac-container:after {
    height: 0;
    background-image: none !important;
}